import axios from 'axios';
import TokenService from '../Storage/Token'
import UserService from '../Storage/UserData'
import { ApiErrorTrackingService } from '../ErrorTracking';
import AuthService from '../Session';

const ApiService = {
  interceptor: null,

  init(baseURL) {
    axios.defaults.baseURL = baseURL;
  },

  setHeader() {
    axios.defaults.headers.common.Authorization = `Bearer ${TokenService.getToken()}`;
  },

  removeHeader() {
    axios.defaults.headers.common = {};
  },

  get(resource) {
    return axios.get(resource);
  },

  post(resource, data) {
    return axios.post(resource, data);
  },

  patch(resource, data) {
    return axios.patch(resource, data);
  },

  put(resource, data) {
    return axios.put(resource, data);
  },

  delete(resource) {
    return axios.delete(resource);
  },

  customRequest(data) {
    return axios(data);
  },

  mountReponseInterceptor() {
    this.interceptor = axios.interceptors.response.use(
      (response) => response,
      (error) => ApiErrorTrackingService.handleResponseError(error),
    );
  },
};

let isRefreshing = false;
let failedRequestsQueue = [];

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  // eslint-disable-next-line consistent-return
  (error) => {
    if (error.response.status === 401) {
      if (error.response.data?.error === "Signature has expired" || error.response.data.status === "error" || error.response.data?.error === "jwt expired") {
        const originalConfig = error.config;
        if (!isRefreshing) {
          isRefreshing = true;

          AuthService.newAcessToken()
            .then((response) => {
              // eslint-disable-next-line camelcase
              const { access_token, refresh_token } = response.data;

              TokenService.saveToken(access_token);
              TokenService.saveRefreshToken(refresh_token);

              // eslint-disable-next-line camelcase
              axios.defaults.headers.Authorization = `Bearer ${access_token}`;

              failedRequestsQueue.forEach((request) =>
                request.onSuccess(access_token),
              );
              failedRequestsQueue = [];
            })
            .catch((error) => {
              TokenService.removeToken();
              UserService.removeUser();
              ApiService.removeHeader();
              document.location.reload(true);
              failedRequestsQueue.forEach((request) =>
                request.onFailure(error),
              );
              failedRequestsQueue = [];
            })
            .finally(() => {
              isRefreshing = false;
            });

          return new Promise((resolve, reject) => {
            failedRequestsQueue.push({
              onSuccess: (token) => {
                originalConfig.headers.Authorization = `Bearer ${token}`;
                resolve(axios(originalConfig));
              },
              onFailure: (error) => {
                reject(error);
              },
            });
          });
        }
      } else if (error.response.data.error === "Token já revogado" || error.response.data.error === "Token revogado") {
        TokenService.removeToken();
        UserService.removeUser();
        ApiService.removeHeader();
        document.location.reload(true)
      } else {
        return Promise.reject(error);
      }
    } else {
      return Promise.reject(error);
    }
  },
);

export default ApiService;