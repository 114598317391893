import {
  AuthenticationError,
  ConnectionError,
  ConflictError,
  BadRequestError,
  NotFoundError,
  CredentialError
} from './Errors';

export default {
  handleResponseError(error) {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          if (error.response.data.error === "Credenciais inválidas"){
            throw new CredentialError(
              error.response.status,
              error.response.data.error
            )
          }
          throw new AuthenticationError(
            error.response.status,
            error.response.data.message,
          );
        case 400:
          throw new BadRequestError(
            error.response.status,
            error.response.data.message,
          );
        case 409:
          return error.response
          
        case 422:
          return error.response
        case 404:
          throw new NotFoundError(error.response.status);
        default:
          throw new Error(
            `Problemas na requisição com servidor de dados. Código: ${error.response.status}`,
          );
      }
    }
    throw new ConnectionError();
  },
};
